import {Fragment, useCallback, useEffect, useState} from 'react'
import {useTranslation} from 'react-i18next'
import {useHistory} from 'react-router-dom'
import WertModule from '@wert-io/module-react-component'
import WertWidget from '@wert-io/widget-initializer'
import {signSmartContractData} from '@wert-io/widget-sc-signer'
import {message} from 'antd'
import BigNumber from 'bignumber.js'
import classNames from 'classnames'
import {ethers} from 'ethers'
import {v4 as uuidv4} from 'uuid'

import Modal from '../../../../components/Modal'
import Successfully from '../../../../components/Successfully/Successfully'
import {COIN_KEY, PROVIDER} from '../../../../constants/constants'
import {TOKEN_STATUS} from '../../../../constants/tokenStatus'
import {storeApi} from '../../../../services/api'
import {useWalletConnectorContext} from '../../../../services/walletConnect'
import {Wallet} from '../../../../services/walletService'
import {useMst} from '../../../../store/store'
import {IPaymentStatus, PAYMENT_STATUS} from '../../../../types/wert'
import {getCoinDecimalsByName} from '../../../../utils/coins'
import Config from '../../../../utils/config'
import myLocalStorage from '../../../../utils/myLocalStorage'
import {shorterAddress} from '../../../../utils/shorterAddress'
import {defaultData, ICreateFormData, PAYMENT_METHOD} from '../../types/CreateFormData'
import {IStepType} from '../../types/Step'
import {IStepperType} from '../../types/Stepper'
import Preview from '../Preview'

import styles from './Stepper.module.scss'

message.config({
  top: 85,
})

const MAX_WIDTH_MOBILE = 470

const Stepper: React.FC<IStepperType> = ({steps, isSingle}) => {
  const history = useHistory()
  const {user} = useMst()
  const {t} = useTranslation()
  const walletConnector = useWalletConnectorContext()
  const getActiveStepByNumber = (number: number) => steps.filter(step => step.number === number)[0]
  const [activeStep, setActiveStep] = useState<IStepType>(getActiveStepByNumber(1))
  const [swipeToRight, setSwipeToRight] = useState<boolean>(true)
  const [data, setData] = useState<ICreateFormData>(defaultData)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [showSuccess, setShowSuccess] = useState<boolean>(false)
  const [wertModalVisible, showWertModal] = useState<boolean>(false)
  const [wertOptions, setWertOptions] = useState<WertWidget['options']>()
  const [loading, setLoading] = useState<boolean>(false)
  const [tokenId, setTokenId] = useState<string | number>()
  const [isMobileWidthResolution, setIsMobileWidthResolution] = useState(
    window.innerWidth <= MAX_WIDTH_MOBILE,
  )

  const handleLastStep = () => {
    if (activeStep.number > 1) {
      if (swipeToRight) setSwipeToRight(false)
      setActiveStep(getActiveStepByNumber(activeStep.number - 1))
    }
  }
  const handleNextStep = () => {
    if (steps.length > activeStep.number) {
      if (!swipeToRight) setSwipeToRight(true)
      setActiveStep(getActiveStepByNumber(activeStep.number + 1))
    } else if (steps.length === activeStep.number) {
      setShowPreview(true)
    }
  }
  const onSubmit = (parcialData: any) => {
    setData({...data, ...parcialData})
  }
  const onClear = () => history.push(`/item/${tokenId}`)

  const onPaymentStatusChange = useCallback(
    (paymentStatus: IPaymentStatus, id: number) => {
      try {
        if (
          paymentStatus.status === PAYMENT_STATUS.PENDING ||
          paymentStatus.status === PAYMENT_STATUS.PROGRESS
        ) {
          debugger
          storeApi.trackMinerTransaction(
            (tokenId as number) || id,
            paymentStatus?.order_id,
            paymentStatus?.payment_id,
            'Mint_wert',
          )
          myLocalStorage.set(
            `${shorterAddress(user.address, 8)}_${TOKEN_STATUS.EXCHANGE_PENDING}_${tokenId}`,
            true,
          )
          setLoading(false)
          setShowSuccess(true)
          showWertModal(false)
        }
        if (
          paymentStatus.status === PAYMENT_STATUS.FAILED ||
          paymentStatus.status === PAYMENT_STATUS.FAILOVER
        ) {
          setLoading(false)
          setShowSuccess(false)
          showWertModal(false)

          message.error({
            content: t('wert.paymentError'),
            className: styles.errorMessage,
          })
        }
      } catch (error) {
        setLoading(false)
        setShowSuccess(false)
        showWertModal(false)

        message.error({
          content: t('wert.paymentError'),
          className: styles.errorMessage,
        })
      }
    },
    [t, tokenId, user.address],
  )

  const mintWithCreditCard = useCallback(
    async (mintData: any): Promise<any> => {
      try {
        const userAccount: any = await walletConnector.walletService.getAccount()
        const userAddress = userAccount.address

        const signedData: WertWidget['options'] = signSmartContractData(
          {
            address: userAddress,
            commodity:
              process.env.REACT_APP_WERT_COMODITY_SYMBOL || COIN_KEY.MATIC.toLocaleUpperCase(),
            commodity_amount: 10 || +(process.env.REACT_APP_WERT_COMODITY_AMOUNT || 5),
            sc_address: mintData.initial_tx.to,
            sc_input_data: mintData.initial_tx.data,
            network: process.env.REACT_APP_WERT_NETWORK || 'Mumbai',
          },
          process.env.REACT_APP_PRIVATE_KEY ||
            '0x57466afb5491ee372b3b30d82ef7e7a0583c9e36aef0f02435bd164fe172b1d3',
        )

        let otherWidgetOptions: WertWidget['options'] = {
          partner_id: process.env.REACT_APP_PARTNER_ID, // your partner id
          click_id: uuidv4(), // unique id of purhase in your system
          width: isMobileWidthResolution ? window.innerWidth - 90 : 470,
          height: 670,
          theme: 'dark',
          color_background: '#1E1F25',
          listeners: {
            'close': (closeData: any) => console.log('close', closeData),
            'error': (errorData: {name: string; message: string}) => {
              message.error({
                content: errorData.message,
                className: styles.errorMessage,
              })
            },
            'payment-status': (paymentStatus: IPaymentStatus) =>
              onPaymentStatusChange(paymentStatus, tokenId || mintData.token.id),
          },
        }
        if (!Config.isProduction) {
          otherWidgetOptions = {...otherWidgetOptions, origin: 'https://sandbox.wert.io'}
        }
        const nftOptions: WertWidget['options'] = {
          extra: {
            item_info: {
              author: mintData?.token?.creator?.name || '',
              image_url:
                ((mintData?.token?.format || data.format) !== 'video' &&
                  (mintData?.token?.media || data.img)) ||
                '',
              name: mintData?.token?.name || data.name,
              seller: mintData?.token?.creator?.name || '',
            },
          },
        }

        setWertOptions({
          ...signedData,
          ...otherWidgetOptions,
          ...nftOptions,
        })

        setShowPreview(false)
        setLoading(false)
        showWertModal(true)
      } catch (err) {
        setLoading(false)
        return Promise.reject()
      }
      return Promise.resolve()
    },
    [
      data.format,
      data.img,
      data.name,
      isMobileWidthResolution,
      onPaymentStatusChange,
      walletConnector.walletService,
      tokenId,
    ],
  )

  const onCreateNft = () => {
    setLoading(true)
    const createFormData = new FormData()
    createFormData.append('media', data.img)
    if (data.cover) createFormData.append('cover', data.cover)
    createFormData.append('name', data.name)
    createFormData.append('total_supply', isSingle ? '1' : data.total_supply.toString())
    createFormData.append('description', data.description)
    if (data.instantSalePrice && data.putOnSale) {
      createFormData.append('price', data.instantSalePriceEth.toString())
    }
    if (!data.instantSalePrice && data.putOnSale) {
      createFormData.append('minimal_bid', data.bid.toString())
    }
    createFormData.append('available', (data.putOnSale && data.total_supply.toString()) || '0')
    createFormData.append('creator_royalty', data.tokenRoyalties)
    createFormData.append('standart', isSingle ? 'ERC721' : 'ERC1155')
    createFormData.append('collection', data.collectionId)
    createFormData.append('currency', data.currency)
    createFormData.append('format', data.format.toUpperCase())
    createFormData.append('tag', data.tag.toUpperCase())
    createFormData.append('selling', data.putOnSale.toString())
    if (data.unlockOncePurchased) {
      createFormData.append('digital_key', data.digitalKey)
    }

    if (data.tokenProperties[0].size) {
      const details: any = {}
      data.tokenProperties.forEach(item => {
        if (item.size) {
          details[item.size] = item.amount
        }
      })

      createFormData.append('details', JSON.stringify(details))
    }

    storeApi
      .createToken(createFormData)
      .then(({data: createData}: any) => {
        createData.initial_tx.gasPrice *= 2
        if (createData.token.id) setTokenId(createData.token.id)

        // PARCHE - TODO: Delegar en el SendTransaction
        createData.initial_tx.value =
          localStorage.kephi_nft_providerName === PROVIDER.SEQUENCE
            ? ethers.utils.parseEther(
                Wallet.weiToEth(createData.initial_tx.value, getCoinDecimalsByName(data.currency)),
              )
            : new BigNumber(createData.initial_tx.value).toFixed(0)

        if (data.paymentMethod === PAYMENT_METHOD.CREDIT_CARD) {
          mintWithCreditCard(createData)
          return
        }

        walletConnector.walletService
          .sendTransaction(createData.initial_tx)
          .then((response: any) => {
            setLoading(false)
            setShowPreview(false)
            setShowSuccess(true)
            storeApi.trackMinerTransaction(
              createData.token.id,
              response.transactionHash || response.hash,
              response.blockNumber,
              'Mint',
            )
            setTokenId(createData.token.id)
          })
          .catch((err: any) => {
            console.log(err, 'err', 'data', createData)
            storeApi.rejectTransaction({type: 'token', id: createData.token.id})
            setLoading(false)
          })
      })
      .catch(error => {
        const {response} = error
        setLoading(false)
        if (!!response?.data && !!response?.data?.name) {
          message.error({
            content: t('create_form.duplicatedName'),
            className: styles.errorMessage,
          })
          setData({...data, isDuplicateName: true})
          setShowPreview(false)
          setActiveStep(getActiveStepByNumber(2))
        }
        if (response?.data) {
          console.log('CreatingNFTErrorData:', response)
        }
        if (error) console.log('CreatingNFTError:', error)
      })
  }

  const isFirstStep = activeStep.number === 1
  const isLastStep = activeStep.number === steps.length
  const isActiveStep = useCallback((number: number) => activeStep.number === number, [activeStep])
  const isStepDone = useCallback((number: number) => activeStep.number > number, [activeStep])

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth <= MAX_WIDTH_MOBILE && !isMobileWidthResolution)
        setIsMobileWidthResolution(true)
      else if (window.innerWidth > MAX_WIDTH_MOBILE && isMobileWidthResolution)
        setIsMobileWidthResolution(false)
    }

    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [isMobileWidthResolution])
  return (
    <>
      <div className={styles.stepperContainer}>
        <div className={styles.stepsContainer}>
          {steps.map(step => (
            <Fragment key={step.number}>
              <div
                className={classNames(styles.separatorLine, {
                  [styles.show]:
                    isActiveStep(step.number) &&
                    !isFirstStep &&
                    swipeToRight &&
                    activeStep.number === 2,
                  [styles.showRight]:
                    isActiveStep(step.number) &&
                    !isFirstStep &&
                    swipeToRight &&
                    activeStep.number !== 2,
                  [styles.showLeft]: isActiveStep(step.number) && !isFirstStep && !swipeToRight,
                })}
              />
              <div
                className={classNames(styles.stepNumber, {
                  [styles.active]: isActiveStep(step.number),
                  [styles.done]: isStepDone(step.number),
                })}>
                {step.number}
              </div>
              <div
                className={classNames(styles.separatorLine, {
                  [styles.show]:
                    isActiveStep(step.number) &&
                    !isLastStep &&
                    !swipeToRight &&
                    activeStep.number === 4,
                  [styles.showRight]: isActiveStep(step.number) && !isLastStep && swipeToRight,
                  [styles.showLeft]:
                    isActiveStep(step.number) &&
                    !isLastStep &&
                    !swipeToRight &&
                    activeStep.number !== 4,
                })}
              />
            </Fragment>
          ))}
        </div>
        {activeStep.form(
          isFirstStep ? undefined : handleLastStep,
          handleNextStep,
          onSubmit,
          data,
          isSingle,
        )}
      </div>
      {showPreview && (
        <Modal visible={showPreview} onClose={() => setShowPreview(false)} closeOnRight>
          <Preview
            className={classNames(styles.preview)}
            mediaURL={
              data.format === 'IMAGE' || data.format === 'PHOTOGRAPHY' || data.format === 'POETRY'
                ? data.preview
                : data.coverPreview
            }
            name={data.name}
            price={data.instantSalePriceEth.toString()}
            format={data.format}
            onClear={onClear}
            currency={data.currency.toUpperCase()}
            bid={data.bid}
            amount={data.total_supply.toString()}
            avatar={user.avatar || ''}
            onCreateNft={onCreateNft}
            isLoading={loading}
          />
        </Modal>
      )}
      {wertModalVisible && (
        <Modal
          containerClassName={styles.wertContainer}
          outerClassName={styles.wertOuter}
          visible={wertModalVisible}
          onClose={() => showWertModal(false)}>
          {wertModalVisible && !!wertOptions && <WertModule options={wertOptions} />}
        </Modal>
      )}

      {showSuccess && (
        <Modal
          outerClassName={styles.modalSuccessOuter}
          visible={showSuccess}
          onClose={onClear}
          closeButtonHidden>
          <Successfully
            onClose={onClear}
            titleText={t('token.mintSuccessTitle')}
            subtitleText={t('token.mintSuccessSubtitle')}
            infoText={t('token.note')}
            closeText={t('token.seeMyNft')}
          />
        </Modal>
      )}

      {loading && <div className={styles.parentDisable} />}
    </>
  )
}

export default Stepper
