import {getCoinNameByType} from '../../../utils/coins'

export interface IProperty {
  size: string | number
  amount: string | number
}

export enum PAYMENT_METHOD {
  WALLET = 'WALLET',
  CREDIT_CARD = 'CREDIT_CARD',
}
export type PaymentMethodType = keyof typeof PAYMENT_METHOD

export interface ICreateFormData {
  img: any
  preview: string
  coverPreview: string
  putOnSale: boolean
  instantSalePrice: boolean
  instantSalePriceEth: string
  cover: any
  name: string
  description: string
  tokenRoyalties: string
  total_supply: number | string
  tokenProperties: IProperty[]
  details: string
  isSingle?: boolean
  isLoading: boolean
  collectionId: string
  currency: string
  bid: string
  format: string
  tag: string
  showModal: boolean
  unlockOncePurchased: boolean
  digitalKey: string
  isDuplicateName?: boolean
  paymentMethod?: PaymentMethodType
}

export const defaultData: ICreateFormData = {
  img: '',
  cover: '',
  preview: '',
  coverPreview: '',
  putOnSale: true,
  instantSalePrice: true,
  unlockOncePurchased: false,
  format: '',
  instantSalePriceEth: '',
  digitalKey: '',
  name: '',
  tag: '',
  description: '',
  tokenRoyalties: '10',
  total_supply: '',
  tokenProperties: [
    {
      size: '',
      amount: '',
    },
  ],
  details: '',
  isLoading: false,
  collectionId: '',
  currency: getCoinNameByType('usd') || getCoinNameByType('main'),
  bid: '',
  showModal: false,
  paymentMethod: PAYMENT_METHOD.WALLET,
}
