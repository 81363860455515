import {cast, flow, types} from 'mobx-state-tree'

import {COIN_KEY, COIN_TYPE, COINS_PER_CHAIN} from '../constants/constants'
import {ratesApi, userApi} from '../services/api'
import {Coin} from '../types/coinsPerChain'

const Follower = types.model({
  avatar: types.maybeNull(types.optional(types.string, '')),
  name: types.optional(types.string, ''),
  his_followers: types.optional(types.number, 0),
  id: types.optional(types.union(types.number, types.string, types.null), null),
})

// export const Balance = types.model({
//   main: types.optional(types.string, '0'),
//   usd: types.optional(types.string, '0'),
//   wrap: types.optional(types.string, '0'),
//   kphi: types.optional(types.string, '0'),
//   // [x typeof string]: types.optional(types.string, '0'),
// })

export interface Balance {
  amount: string
  symbol: keyof typeof COIN_KEY
  type?: COIN_TYPE
}

export interface Rate {
  rate: string
  symbol: keyof typeof COIN_KEY
  image: string
}

/* eslint-disable no-param-reassign */
export const User = types
  .model({
    address: types.string,
    avatar: types.optional(types.maybeNull(types.string), null),
    bio: types.optional(types.maybeNull(types.string), null),
    custom_url: types.optional(types.maybeNull(types.string), null),
    display_name: types.optional(types.maybeNull(types.string), null),
    followers: types.optional(types.array(Follower), []),
    followers_count: types.optional(types.maybeNull(types.number), null),
    follows: types.optional(types.array(Follower), []),
    follows_count: types.optional(types.maybeNull(types.number), null),
    id: types.optional(types.union(types.number, types.string), ''),
    cover: types.optional(types.maybeNull(types.string), null),
    is_verificated: types.boolean,
    likes: types.optional(types.number, 0),
    site: types.optional(types.maybeNull(types.string), null),
    twitter: types.optional(types.maybeNull(types.string), null),
    instagram: types.optional(types.maybeNull(types.string), null),
    facebook: types.optional(types.maybeNull(types.string), null),
    email: types.optional(types.maybeNull(types.string), null),
    // balance: types.optional(Balance, {main: '0', usd: '0', wrap: '0', kphi: '0'}),
    // balances: types.optional(Balance, {main: '0', usd: '0', wrap: '0', kphi: '0'}),
    rates: types.array(types.frozen<Rate>()),
    isLoadingRates: types.optional(types.maybeNull(types.boolean), true),
    balances: types.array(types.frozen<Balance>()),
    isLoadingBalance: types.optional(types.maybeNull(types.boolean), true),
    coins: types.array(types.frozen<Coin>()),
    search: types.optional(types.string, ''),
    is_searching: types.optional(types.maybeNull(types.boolean), null),
    my_referral_code: types.string,
  })
  // .views((self) => ({
  //   isLiked(id: number) {
  //     return !!self.likes.includes(id);
  //   },
  // }))
  .actions(self => {
    const setAddress = (addr: string) => {
      self.address = addr
    }
    // const setBalance = (value: string, currency: 'main' | 'usd' | 'wrap' | 'kphi') => {
    //   self.isLoadingBalance = false
    //   if (currency === 'main') self.balance.main = value
    //   if (currency === 'usd') self.balance.usd = value
    //   if (currency === 'wrap') self.balance.wrap = value
    //   if (currency === 'kphi') self.balance.kphi = value
    // }
    const setBalances = (data: Balance[]) => {
      self.balances = cast(data)
      self.isLoadingBalance = false
    }
    const setRates = (ratesData: Rate[]) => {
      self.rates = cast(
        ratesData.map(rate => {
          return {...rate, symbol: rate.symbol.toLowerCase()} as Rate
        }),
      )
      self.isLoadingRates = false
    }
    // eslint-disable-next-line func-names
    const refreshRates = flow(function* () {
      const response: Rate[] = yield ratesApi.getRates().then(({data}) =>
        data.map((rate: Rate) => {
          return {...rate, symbol: rate.symbol.toLowerCase()}
        }),
      )
      self.isLoadingRates = false
      self.rates = cast(response)
    })
    const setCoins = () => {
      self.coins = cast(
        COINS_PER_CHAIN.find(
          coinsPerChain => coinsPerChain.chainName === localStorage.kephi_nft_chainName,
        )?.coins || [],
      )
    }
    // const addLike = (tokenId: number) => {
    //   self.likes.push(tokenId);
    // };
    const addLike = () => {
      self.likes += 1
    }
    const removeLike = () => {
      self.likes -= 1
    }
    // const removeLike = (tokenId: number) => {
    //   self.likes.replace(self.likes.filter((like) => like !== tokenId));
    // };
    const setCover = (img: string) => {
      self.cover = img
    }
    const setSearch = (srch: string) => {
      self.search = srch
    }
    const setIsSearching = (isSrch: boolean) => {
      self.is_searching = isSrch
    }
    const update = (userData: any) => {
      self.address = userData.address
      self.avatar = userData.avatar && userData.avatar
      self.bio = userData.bio
      self.cover = userData.cover
      self.custom_url = userData.custom_url
      self.display_name = userData.display_name
      self.followers = userData.followers
      self.followers_count = userData.followers_count
      self.follows = userData.follows
      self.follows_count = userData.follows_count
      self.id = userData.id
      self.instagram = userData.instagram
      self.facebook = userData.facebook
      self.is_verificated = userData.is_verificated
      self.likes = userData.likes
      self.site = userData.site
      self.twitter = userData.twitter
      self.email = userData.email
      self.my_referral_code = userData.my_referral_code
      setCoins()
    }
    const disconnect = () => {
      self.address = ''
      self.id = ''
      self.search = ''
      delete localStorage.address
      delete localStorage.kephi_nft_token
      delete localStorage.nft_metamask
      delete localStorage.kephi_nft_chainName
      delete localStorage.kephi_nft_providerName
      delete localStorage.walletconnect
    }
    const getMe = flow(function* getMe() {
      try {
        const {data} = yield userApi.getMe()
        update(data)
      } catch (err) {
        console.log(err)
        disconnect()
      }
    })

    return {
      setAddress,
      setCover,
      addLike,
      removeLike,
      update,
      getMe,
      disconnect,
      setSearch,
      setIsSearching,
      setBalances,
      setRates,
      refreshRates,
    }
  })

/* eslint-disable no-param-reassign */
