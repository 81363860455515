import {ReactNode} from 'react'
import {Controller, useForm} from 'react-hook-form'
import {useTranslation} from 'react-i18next'
import classNames from 'classnames'

import {ReactComponent as CreditCard} from '../../../../../assets/img/icons/credit-card.svg'
import {ReactComponent as Wallet} from '../../../../../assets/img/icons/wallet.svg'
import Switch from '../../../../../components/Switch'
import TextInput from '../../../../../components/TextInput'
import {BLOCKCHAIN} from '../../../../../constants/constants'
import {PAYMENT_METHOD, PaymentMethodType} from '../../../types/CreateFormData'
import {ICreateForm} from '../../../types/Stepper'

import styles from './WeAreReady.module.scss'

const UNLOCK_ONCE_PURCHASED = 'unlockOncePurchased'
const DIGITAL_KEY = 'digitalKey'
const TOKEN_ROYALTIES = 'tokenRoyalties'
const PAYMENT_METHOD_PROPERTY = 'paymentMethod'

export type WeAreReadyForm = {
  unlockOncePurchased: boolean
  digitalKey: string
  tokenRoyalties: string
  paymentMethod: PaymentMethodType
}

interface IPaymentMethod {
  labelKey: string
  method: PaymentMethodType
  icon: ReactNode
}

const paymentMethods: IPaymentMethod[] = [
  {
    labelKey: 'wallet',
    method: PAYMENT_METHOD.WALLET,
    icon: <Wallet />,
  },
  {
    labelKey: 'creditCard',
    method: PAYMENT_METHOD.CREDIT_CARD,
    icon: <CreditCard />,
  },
]

const WeAreReadyStep: React.FC<ICreateForm> = ({lastStep, nextStep, onSubmit, currentData}) => {
  const {t} = useTranslation()

  const {
    handleSubmit,
    setValue,
    watch,
    control,
    formState: {errors},
  } = useForm<WeAreReadyForm>({
    defaultValues: {
      unlockOncePurchased: currentData.unlockOncePurchased,
      digitalKey: currentData.digitalKey,
      tokenRoyalties: currentData.tokenRoyalties,
      paymentMethod: currentData.paymentMethod,
    },
  })

  const [unlockOncePurchased, paymentMethodSelected] = watch([
    UNLOCK_ONCE_PURCHASED,
    PAYMENT_METHOD_PROPERTY,
  ])

  const handleApply = (data: WeAreReadyForm) => {
    onSubmit(data)
    nextStep()
  }

  const onError = (data: any) => console.log('Error', data)

  return (
    <form className={styles.container} onSubmit={handleSubmit(handleApply, onError)}>
      <div className={styles.title}>{t('create_form.weAreReady.title')}</div>
      <div className={styles.option}>
        <div className={styles.box}>
          <div className={styles.flexBox}>
            <div className={styles.category}>
              {t('create_form.weAreReady.hiddenContentQuestion')}
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name={UNLOCK_ONCE_PURCHASED}
          render={({field: {name, value, onChange}}) => (
            <Switch
              className={styles.switch}
              customClasses={{
                input: styles.switchInput,
                inner: styles.switchInner,
                box: styles.switchBox,
              }}
              id={name}
              value={value}
              setValue={(unlockOncePurchasedValue: boolean) => {
                onChange(unlockOncePurchasedValue)
                setValue(DIGITAL_KEY, '')
              }}
            />
          )}
        />
      </div>
      {unlockOncePurchased && (
        <Controller
          control={control}
          name={DIGITAL_KEY}
          rules={{
            required: {
              value: !!unlockOncePurchased,
              message: t('validate.digitalKey'),
            },
          }}
          render={({field: {name, value, onChange, onBlur}}) => (
            <>
              <textarea
                className={classNames(styles.textAreaInput, {[styles.error]: !!errors[name]})}
                name={name}
                value={value}
                placeholder={t('create_form.weAreReady.hiddenContentPlaceholder')}
                onChange={onChange}
                onBlur={onBlur}
                rows={3}
              />
              <div className={classNames(styles.errorContainer, {[styles.error]: !!errors[name]})}>
                <div role="alert" className={styles.errorLabel}>
                  {errors[name]?.message}
                </div>
              </div>
              <></>
            </>
          )}
        />
      )}
      <div className={styles.option}>
        <div className={styles.box}>
          <div className={styles.flexBox}>
            <div className={styles.category}>
              {t('create_form.weAreReady.receiveRoyaltiesQuestion')}
            </div>
          </div>
        </div>
        <Controller
          control={control}
          name={TOKEN_ROYALTIES}
          rules={{
            required: {
              value: true,
              message: t('validate.tokenRoyalties1'),
            },
            max: {
              value: 10,
              message: t('validate.royaltiesMaxLimitToTenPercent'),
            },
          }}
          render={({field: {name, value, onChange, onBlur}}) => (
            <TextInput
              className={styles.royaltiesField}
              name={name}
              value={value}
              type="number"
              onChange={onChange}
              onBlur={onBlur}
              placeholder="10"
              suffix="%"
              positiveOnly
              integer
              error={!!errors[name]}
            />
          )}
        />
      </div>
      {localStorage?.kephi_nft_chainName === BLOCKCHAIN.POLYGON && (
        <>
          <div className={styles.title}>{t('create_form.weAreReady.choosePaymentMode')}</div>
          <div className={styles.option}>
            <div className={styles.row}>
              {paymentMethods.map((paymentMethod: IPaymentMethod) => (
                <div
                  key={paymentMethod.method}
                  className={classNames(styles.paymentMethod, {
                    [styles.selected]: paymentMethodSelected === paymentMethod.method,
                  })}
                  onClick={() => setValue('paymentMethod', paymentMethod.method)}
                  tabIndex={0}
                  onKeyDown={() => {}}
                  role="button">
                  {paymentMethod.icon}
                  <span>{t(`create_form.weAreReady.${paymentMethod.labelKey}`)}</span>
                </div>
              ))}
            </div>
          </div>
        </>
      )}
      <div
        className={classNames(styles.errorRoyaltiesContainer, {
          [styles.error]: !!errors[TOKEN_ROYALTIES] && !!errors[TOKEN_ROYALTIES]?.message,
        })}>
        <div role="alert" className={styles.errorRoyaltiesLabel}>
          {errors[TOKEN_ROYALTIES]?.message}
        </div>
      </div>
      <div className={styles.buttonsRow}>
        {lastStep && (
          <button
            className={classNames('button-outlined-gradient', styles.backButton)}
            type="button"
            onClick={() => lastStep()}>
            {t('back')}
          </button>
        )}
        <button className={classNames('button-gradient', styles.nextbutton)} type="submit">
          {t('create_form.preview')}
        </button>
      </div>
    </form>
  )
}

export default WeAreReadyStep
